<template>
	<div class="doctor_management margin-20">
    <!--  列表  -->
		<el-row>
			<el-col :span="24">
				<div class="grid-content">
					<template>
						<div class="form-style">
							<el-form ref="formInline" :inline="true" :model="formInline" label-width="150px" class="demo-form-inline">
                <el-form-item label="厂家" prop="manufacturerId">
                  <el-select ref="selection" style="width:100%" clearable v-model="formInline.manufacturerId" placeholder="请选择">
                    <el-option v-for="item in manufactures" :key="item.no" :label="item.label" :value="item.no"> </el-option>
                  </el-select>
                </el-form-item>
								<el-form-item>
									<el-button type="primary" @click="search">搜索</el-button>
									<el-button @click="resetForm('formInline')">重置</el-button>
									<el-button type="primary" @click="addEdit('新增',{})">新增</el-button>
								</el-form-item>
							</el-form>
						</div>
						<div class="tableMargin">
							<el-table ref="multipleTable" :header-cell-style="{background:'#eef1f6'}" :data="pageData" style="width: 100%">
								<el-table-column align="center" prop="manufacturerName" label="厂商名称"></el-table-column>
                <el-table-column align="center" prop="name" label="电极名称"></el-table-column>
								<el-table-column label="操作">
									<template slot-scope="scope">
										<el-button type="primary" @click="addEdit('编辑',scope.row)">编辑</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<v-paging v-on:childPage="pages" :total=total></v-paging>
					</template>
				</div>
			</el-col>
		</el-row>
		<!-- 编辑新增模态框 -->
		<el-dialog :title="title" :visible.sync="dialogVisible" width="40%" center>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="厂家" prop="manufacturerId">
          <el-select ref="selection" style="width:100%" clearable v-model="ruleForm.manufacturerId" placeholder="请选择">
            <el-option v-for="item in manufactures" :key="item.no" :label="item.label" :value="item.no"> </el-option>
          </el-select>
        </el-form-item>
				<el-form-item label="名称" prop="name">
					<el-input clearable v-model="ruleForm.name" placeholder="请输入电极名称"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveUpdate('ruleForm')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		components: {
			vPaging
		},
		methods: {
			//分页接口
			async queryPage(current = 1, size = 10){
        let info={
          current:current,
          size:size,
          manufacturerId:this.formInline.manufacturerId,
          menuId:this.menuCode,
        }
        let res = await this.$Http.ElectrodeNameQueryPage(info)
				if (res.code == '200') {
					this.pageData = res.data.data;
					this.total = res.data.total;
				} else {
					this.pageData = [];
					this.total = 0;
					this.$message.error(res.message);
				}
			},

      //厂家下拉
      async queryManufacture() {
        let obj = {
          parentCode: "100010",
          menuId: this.menuCode,
        }
        let res = await this.$Http.ManufactureSelect(obj);
        if (res.code == '200') {
          this.manufactures = res.data;
        } else {
          this.$message.error(res.message);
        }
      },

      //编辑新增
      addEdit(val, item) {
        this.ruleForm = JSON.parse(JSON.stringify(item));
        this.dialogVisible = true;
        this.title = `${val}电极名称`;
        this.status=item.status;
      },

      //保存
      saveUpdate(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.title=='新增电极名称' ? this.insert() : this.update();
          }
        });
      },

    //新增接口
      async insert(){
        let info={
          manufacturerId:this.ruleForm.manufacturerId,
          name:this.ruleForm.name
        }
        let res = await this.$Http.ElectrodeNameInsert(info);
        this.dialogVisible=false;
        if (res.code == '200') {
          this.queryPage();
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      },

    //更新接口
      async update(){
        let info={
          id:this.ruleForm.id,
          manufacturerId:this.ruleForm.manufacturerId,
          name:this.ruleForm.name,
          status:this.status
        }
        let res = await this.$Http.ElectrodeNameUpdate(info);
        this.dialogVisible=false;
        if (res.code == '200') {
          this.queryPage();
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      },

    //分页组件
      pages(current, size) {
        this.queryPage(current, size);
      },

      // 搜索
      search() {
        this.queryPage();
      },

      //重置
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
		},

		data() {
			return {
				menuCode: JSON.parse(localStorage.getItem('menuList'))[this.$route.name],
        newStatus:false,
        title: "",
        formInline: {},
        pageData: [],
        total: 0,
        manufactures:[],
        dialogVisible: false,
				ruleForm: {
          manufacturerId:'',
          name:''
        },
				rules: {
          // manufacturerId: [
          //   { required: true, message: '请选择厂家', trigger: 'blur' },
          // ],
          // name: [
          //   { required: true, message: '电极名称不能为空', trigger: 'blur' },
          // ]
        }
			}
		},
		created() {},
		mounted() {
			//分页
			this.queryPage();

      //厂家下拉
      this.queryManufacture();
		}
	}
</script>

<style scoped>
</style>
